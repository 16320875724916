import m from "mithril";
import router from "./bin/router.js";
import * as routes from "./routes/index.js";

/**
 * createApp
 * @param {object} args
 * @param {string} args.defaultRoute
 * @param {object} args.shell
 */
export async function createApp({ defaultRoute, shell }) {
  m.route.prefix = "";
  m.route(shell, defaultRoute, router(routes));
}
