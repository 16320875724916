import { parseClass } from "../../../../utils/css-class.js";

export const CTA = {
  showLearnMore: false,
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass([
          "relative",
          "-z-10",
          "mt-52",
          "px-6",
          "lg:px-8",
        ]),
      },
      [
        m(
          "div",
          {
            className: parseClass([
              "absolute",
              "inset-x-0",
              "top-1/2",
              "-z-10",
              "flex",
              "-translate-y-1/2",
              "transform-gpu",
              "justify-center",
              "overflow-hidden",
              "blur-3xl",
              "sm:bottom-0",
              "sm:right-[calc(50%-6rem)]",
              "sm:top-auto",
              "sm:translate-y-0",
              "sm:transform-gpu",
              "sm:justify-end",
            ]),
            "aria-hidden": true,
          },
          [
            m("div", {
              className: parseClass([
                "aspect-[1108/632]",
                "w-[69.25rem]",
                "flex-none",
                "bg-gradient-to-r",
                "from-[#ff80b5]",
                "to-[#9089fc]",
                "opacity-25",
              ]),
              style: {
                "clip-path":
                  "polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)",
              },
            }),
          ],
        ),

        m(
          "div",
          {
            className: parseClass(["mx-auto", "max-w-2xl", "text-center"]),
          },
          [
            m(
              "h2",
              {
                className: parseClass([
                  "text-3xl",
                  "font-bold",
                  "tracking-tight",
                  "text-gray-900",
                  "sm:text-4xl",
                ]),
              },
              [
                "Boost your school's productivity.",
                m("br"),
                "Get onboard our platform today.",
              ],
            ),

            m(
              "p",
              {
                className: parseClass([
                  "mx-auto",
                  "mt-6",
                  "max-w-xl",
                  "text-lg",
                  "leading-8",
                  "text-gray-600",
                ]),
              },
              [
                "Enhance your school's efficiency and streamline operations with our all-in-one platform. Join today and take productivity to the next level.",
              ],
            ),

            m(
              "div",
              {
                className: parseClass([
                  "mt-10",
                  "flex",
                  "items-center",
                  "justify-center",
                  "gap-x-6",
                ]),
              },
              [
                m(
                  "a",
                  {
                    href: base.app,
                    className: parseClass([
                      "btn",
                      "btn-md",
                      "btn-neutral",
                      "rounded",
                    ]),
                  },
                  ["Get Started"],
                ),

                this.showLearnMore &&
                  m(
                    "a",
                    {
                      href: "#",
                      className: parseClass([
                        "text-sm",
                        "font-semibold",
                        "leading-6",
                        "text-gray-900",
                      ]),
                    },
                    ["Learn more", m("span", { "aria-hidden": true }, " →")],
                  ),
              ],
            ),
          ],
        ),

        m(
          "div",
          {
            className: parseClass([
              "absolute",
              "left-1/2",
              "right-0",
              "top-full",
              "-z-10",
              "hidden",
              "-translate-y-1/2",
              "transform-gpu",
              "overflow-hidden",
              "blur-3xl",
              "sm:block",
            ]),
            "aria-hidden": true,
          },
          [
            m("div", {
              className: parseClass([
                "aspect-[1155/678]",
                "w-[72.1875rem]",
                "bg-gradient-to-tr",
                "from-[#ff80b5]",
                "to-[#9089fc]",
                "opacity-30",
              ]),
              style: {
                "clip-path":
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              },
            }),
          ],
        ),
      ],
    );
  },
};
