import { parseClass } from "../../../utils/css-class.js";

export const Footer = {
  useFooter: false,
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass([
          "mx-auto",
          "mt-32",
          "max-w-7xl",
          "px-6",
          "lg:px-8",
        ]),
      },
      [
        m(
          "footer",
          {
            "aria-labelledby": "footer-heading",
            className: parseClass([
              "relative",
              "border-t",
              "border-gray-900/10",
              "py-24",
              "sm:mt-56",
              "sm:py-32",
            ]),
          },
          [
            m(
              "h2",
              {
                id: "footer-heading",
                className: parseClass(["sr-only"]),
              },
              ["Footer"],
            ),

            m(
              "div",
              {
                className: parseClass([
                  "xl:grid",
                  "xl:grid-cols-3",
                  "xl:gap-8",
                ]),
              },
              [
                m("div", {}, [
                  m(
                    "label",
                    { className: parseClass(["font-bold"]) },
                    "Results Africa",
                  ),

                  m(
                    "p",
                    {
                      className: parseClass([
                        "text-xs",
                        "leading-5",
                        "text-gray-500",
                      ]),
                    },
                    [
                      m.trust("&copy;"),
                      " ",
                      new Date().getFullYear(),
                      " ",
                      "Results Africa. All rights reserved.",
                    ],
                  ),
                ]),
                // m("img", {
                //   className: parseClass(["h-7"]),
                //   src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
                //   alt: "Company Name",
                // }),

                this.useFooter &&
                  m(
                    "div",
                    {
                      className: parseClass([
                        "mt-16",
                        "grid",
                        "grid-cols-2",
                        "gap-8",
                        "xl:col-span-2",
                        "xl:mt-0",
                      ]),
                    },
                    [
                      m(
                        "div",
                        {
                          className: parseClass([
                            "md:grid",
                            "md:grid-cols-2",
                            "md:gap-8",
                          ]),
                        },
                        [
                          m("div", {}, [
                            m(
                              "h3",
                              {
                                className: parseClass([
                                  "text-sm",
                                  "font-semibold",
                                  "leading-6",
                                  "text-gray-900",
                                ]),
                              },
                              ["Solutions"],
                            ),

                            m(
                              "ul",
                              {
                                role: "list",
                                className: parseClass(["mt-6", "space-y-4"]),
                              },
                              [
                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Hosting"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Data Services"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Uptime Monitoring"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Enterprise Services"],
                                  ),
                                ]),
                              ],
                            ),
                          ]),

                          m(
                            "div",
                            {
                              className: parseClass(["mt-10", "md:mt-0"]),
                            },
                            [
                              m(
                                "h3",
                                {
                                  className: parseClass([
                                    "text-sm",
                                    "font-semibold",
                                    "leading-6",
                                    "text-gray-900",
                                  ]),
                                },
                                ["Support"],
                              ),

                              m(
                                "ul",
                                {
                                  role: "list",
                                  className: parseClass(["mt-6", "space-y-4"]),
                                },
                                [
                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["Pricing"],
                                    ),
                                  ]),

                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["Documentation"],
                                    ),
                                  ]),

                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["Guides"],
                                    ),
                                  ]),

                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["API Reference"],
                                    ),
                                  ]),
                                ],
                              ),
                            ],
                          ),
                        ],
                      ),

                      m(
                        "div",
                        {
                          className: parseClass([
                            "md:grid",
                            "md:grid-cols-2",
                            "md:gap-8",
                          ]),
                        },
                        [
                          m("div", {}, [
                            m(
                              "h3",
                              {
                                className: parseClass([
                                  "text-sm",
                                  "font-semibold",
                                  "leading-6",
                                  "text-gray-900",
                                ]),
                              },
                              ["Company"],
                            ),

                            m(
                              "ul",
                              {
                                role: "list",
                                className: parseClass(["mt-6", "space-y-4"]),
                              },
                              [
                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["About"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Blog"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Jobs"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Press"],
                                  ),
                                ]),

                                m("li", {}, [
                                  m(
                                    "a",
                                    {
                                      href: "#",
                                      className: parseClass([
                                        "text-sm",
                                        "leading-6",
                                        "text-gray-600",
                                        "hover:text-gray-900",
                                      ]),
                                    },
                                    ["Partners"],
                                  ),
                                ]),
                              ],
                            ),
                          ]),

                          m(
                            "div",
                            {
                              className: parseClass(["mt-10", "md:mt-0"]),
                            },
                            [
                              m(
                                "h3",
                                {
                                  className: parseClass([
                                    "text-sm",
                                    "font-semibold",
                                    "leading-6",
                                    "text-gray-900",
                                  ]),
                                },
                                ["Legal"],
                              ),

                              m(
                                "ul",
                                {
                                  role: "list",
                                  className: parseClass(["mt-6", "space-y-4"]),
                                },
                                [
                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["Claim"],
                                    ),
                                  ]),

                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["Privacy"],
                                    ),
                                  ]),

                                  m("li", {}, [
                                    m(
                                      "a",
                                      {
                                        href: "#",
                                        className: parseClass([
                                          "text-sm",
                                          "leading-6",
                                          "text-gray-600",
                                          "hover:text-gray-900",
                                        ]),
                                      },
                                      ["Terms"],
                                    ),
                                  ]),
                                ],
                              ),
                            ],
                          ),
                        ],
                      ),
                    ],
                  ),
              ],
            ),
          ],
        ),
      ],
    );
  },
};
