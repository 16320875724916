import { parseClass } from "../../../../utils/css-class.js";

export const Hero = {
  showLearnMore: false,
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass(["relative", "pt-14"]),
      },
      [
        m(
          "div",
          {
            className: parseClass([
              "absolute",
              "inset-x-0",
              "-top-40",
              "-z-10",
              "transform-gpu",
              "overflow-hidden",
              "blur-3xl",
              "sm:-top-80",
            ]),
            "aria-hidden": "true",
          },
          [
            m("div", {
              className: parseClass([
                "relative",
                "left-[calc(50%-11rem)]",
                "aspect-[1155/678]",
                "w-[36.125rem]",
                "-translate-x-1/2",
                "rotate-[30deg]",
                "bg-gradient-to-tr",
                "from-[#ff80b5]",
                "to-[#9089fc]",
                "opacity-30",
                "sm:left-[calc(50%-30rem)]",
                "sm:w-[72.1875rem]",
              ]),
              style: {
                "clip-path":
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              },
            }),
          ],
        ),

        m("div", { className: parseClass(["py-24", "sm:py-32"]) }, [
          m(
            "div",
            {
              className: parseClass([
                "mx-auto",
                "max-w-7xl",
                "px-6",
                "lg:px-8",
              ]),
            },
            [
              m(
                "div",
                {
                  className: parseClass(["mx-auto max-w-2xl text-center"]),
                },
                [
                  m(
                    "h1",
                    {
                      className: parseClass([
                        "text-4xl",
                        "font-bold",
                        "tracking-tight",
                        "text-gray-900",
                        "sm:text-6xl",
                      ]),
                    },
                    ["One Platform for All Your School Results Needs"],
                  ),

                  m(
                    "p",
                    {
                      className: parseClass([
                        "mt-6",
                        "text-lg",
                        "leading-8",
                        "text-gray-600",
                      ]),
                    },
                    [
                      "Manage exams, assessments, and student performance seamlessly across multiple branches with an all-in-one solution.",
                    ],
                  ),

                  m(
                    "div",
                    {
                      className: parseClass([
                        "mt-10",
                        "flex",
                        "items-center",
                        "justify-center",
                        "gap-x-6",
                      ]),
                    },
                    [
                      m(
                        "a",
                        {
                          href: base.app,
                          className: parseClass([
                            "btn",
                            "btn-md",
                            "btn-neutral",
                            "rounded",
                          ]),
                        },
                        ["Get Started"],
                      ),

                      this.showLearnMore &&
                        m(
                          "a",
                          {
                            href: "#",
                            className: parseClass([
                              "text-sm",
                              "font-semibold",
                              "leading-6",
                              "text-gray-900",
                            ]),
                          },
                          [
                            "Learn more",
                            m("span", { "aria-hidden": true }, [" →"]),
                          ],
                        ),
                    ],
                  ),
                ],
              ),

              m(
                "div",
                {
                  className: parseClass(["mt-16", "flow-root", "sm:mt-24"]),
                },
                [
                  m(
                    "div",
                    {
                      className: parseClass([
                        "-m-2",
                        "rounded-xl",
                        "bg-gray-900/5",
                        "p-2",
                        "ring-1",
                        "ring-inset",
                        "ring-gray-900/10",
                        "lg:-m-4",
                        "lg:rounded-2xl",
                        "lg:p-4",
                      ]),
                    },
                    [
                      m("img", {
                        src: "https://res.cloudinary.com/dfujlprx9/image/upload/v1727464028/Results_Africa_Dashboard_nsst20.png",
                        alt: "App screenshot",
                        width: "2432",
                        height: "1442",
                        className: parseClass([
                          "rounded-md",
                          "shadow-2xl",
                          "ring-1",
                          "ring-gray-900/10",
                        ]),
                      }),
                    ],
                  ),
                ],
              ),
            ],
          ),
        ]),

        m(
          "div",
          {
            className: parseClass([
              "absolute",
              "inset-x-0",
              "top-[calc(100%-13rem)]",
              "-z-10",
              "transform-gpu",
              "overflow-hidden",
              "blur-3xl",
              "sm:top-[calc(100%-30rem)]",
            ]),
            "aria-hidden": true,
          },
          [
            m("div", {
              className: parseClass([
                "relative",
                "left-[calc(50%+3rem)]",
                "aspect-[1155/678]",
                "w-[36.125rem]",
                "-translate-x-1/2",
                "bg-gradient-to-tr",
                "from-[#ff80b5]",
                "to-[#9089fc]",
                "opacity-30",
                "sm:left-[calc(50%+36rem)]",
                "sm:w-[72.1875rem]",
              ]),
              style: {
                "clip-path":
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              },
            }),
          ],
        ),
      ],
    );
  },
};
