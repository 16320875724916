import { parseClass } from "../../../../utils/css-class.js";

export const LogoCloud = {
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass(["mx-auto", "max-w-7xl", "px-6", "lg:px-8"]),
      },
      [
        m(
          "div",
          {
            className: parseClass([
              "mx-auto",
              "grid",
              "max-w-lg",
              "grid-cols-4",
              "items-center",
              "gap-x-8",
              "gap-y-12",
              "sm:max-w-xl",
              "sm:grid-cols-6",
              "sm:gap-x-10",
              "sm:gap-y-14",
              "lg:mx-0",
              "lg:max-w-none",
              "lg:grid-cols-5",
            ]),
          },
          [
            m("img", {
              className: parseClass([
                "col-span-2",
                "max-h-12",
                "w-full",
                "object-contain",
                "lg:col-span-1",
              ]),
              src: "https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg",
              alt: "Transistor",
              width: "158",
              height: "48",
            }),

            m("img", {
              className: parseClass([
                "col-span-2",
                "max-h-12",
                "w-full",
                "object-contain",
                "lg:col-span-1",
              ]),
              src: "https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg",
              alt: "Reform",
              width: "158",
              height: "48",
            }),

            m("img", {
              className: parseClass([
                "col-span-2",
                "max-h-12",
                "w-full",
                "object-contain",
                "lg:col-span-1",
              ]),
              src: "https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg",
              alt: "Tuple",
              width: "158",
              height: "48",
            }),

            m("img", {
              className: parseClass([
                "col-span-2",
                "max-h-12",
                "w-full",
                "object-contain",
                "lg:col-span-1",
              ]),
              src: "https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg",
              alt: "SavvyCal",
              width: "158",
              height: "48",
            }),

            m("img", {
              className: parseClass([
                "col-span-2",
                "max-h-12",
                "w-full",
                "object-contain",
                "lg:col-span-1",
              ]),
              src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
              alt: "Statamic",
              width: "158",
              height: "48",
            }),
          ],
        ),

        m(
          "div",
          {
            className: parseClass(["mt-16", "flex", "justify-center"]),
          },
          [
            m(
              "p",
              {
                className: parseClass([
                  "relative",
                  "rounded-full",
                  "px-4",
                  "py-1.5",
                  "text-sm",
                  "leading-6",
                  "text-gray-600",
                  "ring-1",
                  "ring-inset",
                  "ring-gray-900/10",
                  "hover:ring-gray-900/20",
                ]),
              },
              [
                m(
                  "span",
                  {
                    className: parseClass(["hidden", "md:inline"]),
                  },
                  [
                    "Transistor saves up to $40,000 per year, per employee by working with us.",
                  ],
                ),
                m(
                  "a",
                  {
                    href: "#",
                    className: "font-semibold text-indigo-600",
                  },
                  [
                    m("span", {
                      className: "absolute inset-0",
                      "aria-hidden": true,
                    }),

                    " Read our case study",

                    m("span", { "aria-hidden": true }, [" →"]),
                  ],
                ),
              ],
            ),
          ],
        ),
      ],
    );
  },
};
