import { parseClass } from "../../../../utils/css-class.js";

export const Feature = {
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass([
          "mx-auto",
          "mt-12",
          "max-w-7xl",
          "px-6",
          "sm:mt-36",
          "lg:px-8",
        ]),
      },
      [
        m(
          "div",
          {
            className: parseClass(["mx-auto", "max-w-2xl", "lg:text-center"]),
          },
          [
            m(
              "h2",
              {
                className: parseClass([
                  "text-base",
                  "font-semibold",
                  "leading-7",
                  "text-indigo-600",
                ]),
              },
              ["Simplify your result needs"],
            ),

            m(
              "p",
              {
                className: parseClass([
                  "mt-2",
                  "text-3xl",
                  "font-bold",
                  "tracking-tight",
                  "text-gray-900",
                  "sm:text-4xl",
                ]),
              },
              ["Comprehensive Tools for Effortless Results Management"],
            ),

            m(
              "p",
              {
                className: parseClass([
                  "mt-6",
                  "text-lg",
                  "leading-8",
                  "text-gray-600",
                ]),
              },
              [
                "Efficiently manage every aspect of your school's academic performance with powerful features designed for tracking, analyzing, and reporting student results.",
              ],
            ),
          ],
        ),

        m(
          "div",
          {
            className: parseClass([
              "mx-auto",
              "mt-16",
              "max-w-2xl",
              "sm:mt-20",
              "lg:mt-24",
              "lg:max-w-4xl",
            ]),
          },
          [
            m(
              "dl",
              {
                className: parseClass([
                  "grid",
                  "max-w-xl",
                  "grid-cols-1",
                  "gap-x-8",
                  "gap-y-10",
                  "lg:max-w-none",
                  "lg:grid-cols-2",
                  "lg:gap-y-16",
                ]),
              },
              [
                m(
                  "div",
                  {
                    className: parseClass(["relative", "pl-16"]),
                  },
                  [
                    m(
                      "dt",
                      {
                        className: parseClass([
                          "text-base",
                          "font-semibold",
                          "leading-7",
                          "text-gray-900",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass([
                              "absolute",
                              "left-0",
                              "top-0",
                              "flex",
                              "h-10",
                              "w-10",
                              "items-center",
                              "justify-center",
                              "rounded-lg",
                              "bg-black",
                              "text-white",
                              "text-2xl",
                            ]),
                          },
                          [
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-hierarchy"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                              <path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                              <path d="M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                              <path d="M6.5 17.5l5.5 -4.5l5.5 4.5" />
                              <path d="M12 7l0 6" />
                            </svg>,
                          ],
                        ),
                        "Multi-Branch Management",
                      ],
                    ),

                    m(
                      "dd",
                      {
                        className: parseClass([
                          "mt-2",
                          "text-base",
                          "leading-7",
                          "text-gray-600",
                        ]),
                      },
                      [
                        "Seamlessly oversee multiple school branches from a centralized platform, ensuring consistency and efficiency",
                      ],
                    ),
                  ],
                ),

                m(
                  "div",
                  {
                    className: parseClass(["relative", "pl-16"]),
                  },
                  [
                    m(
                      "dt",
                      {
                        className: parseClass([
                          "text-base",
                          "font-semibold",
                          "leading-7",
                          "text-gray-900",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass([
                              "absolute",
                              "left-0",
                              "top-0",
                              "flex",
                              "h-10",
                              "w-10",
                              "items-center",
                              "justify-center",
                              "rounded-lg",
                              "bg-black",
                              "text-white",
                              "text-2xl",
                            ]),
                          },
                          [
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-hearts"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M14.017 18l-2.017 2l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 0 1 8.153 5.784" />
                              <path d="M15.99 20l4.197 -4.223a2.81 2.81 0 0 0 0 -3.948a2.747 2.747 0 0 0 -3.91 -.007l-.28 .282l-.279 -.283a2.747 2.747 0 0 0 -3.91 -.007a2.81 2.81 0 0 0 -.007 3.948l4.182 4.238z" />
                            </svg>,
                          ],
                        ),
                        "Parents Portal",
                      ],
                    ),

                    m(
                      "dd",
                      {
                        className: parseClass([
                          "mt-2",
                          "text-base",
                          "leading-7",
                          "text-gray-600",
                        ]),
                      },
                      [
                        "Keep parents informed by providing them access to their child's academic progress, attendance, and performance in real-time.",
                      ],
                    ),
                  ],
                ),

                m(
                  "div",
                  {
                    className: parseClass(["relative", "pl-16"]),
                  },
                  [
                    m(
                      "dt",
                      {
                        className: parseClass([
                          "text-base",
                          "font-semibold",
                          "leading-7",
                          "text-gray-900",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass([
                              "absolute",
                              "left-0",
                              "top-0",
                              "flex",
                              "h-10",
                              "w-10",
                              "items-center",
                              "justify-center",
                              "rounded-lg",
                              "bg-black",
                              "text-white",
                              "text-2xl",
                            ]),
                          },
                          [
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-currency-dollar"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />
                              <path d="M12 3v3m0 12v3" />
                            </svg>,
                          ],
                        ),
                        "Fee Management",
                      ],
                    ),

                    m(
                      "dd",
                      {
                        className: parseClass([
                          "mt-2",
                          "text-base",
                          "leading-7",
                          "text-gray-600",
                        ]),
                      },
                      [
                        "Easily track, manage, and process school fees, with automated reminders and detailed financial reports.",
                      ],
                    ),
                  ],
                ),

                m(
                  "div",
                  {
                    className: parseClass(["relative", "pl-16"]),
                  },
                  [
                    m(
                      "dt",
                      {
                        className: parseClass([
                          "text-base",
                          "font-semibold",
                          "leading-7",
                          "text-gray-900",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass([
                              "absolute",
                              "left-0",
                              "top-0",
                              "flex",
                              "h-10",
                              "w-10",
                              "items-center",
                              "justify-center",
                              "rounded-lg",
                              "bg-black",
                              "text-white",
                              "text-2xl",
                            ]),
                          },
                          [
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="icon icon-tabler icons-tabler-outline icon-tabler-report"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                              <path d="M18 14v4h4" />
                              <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
                              <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                              <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                              <path d="M8 11h4" />
                              <path d="M8 15h3" />
                            </svg>,
                          ],
                        ),
                        "In-depth Reporting",
                      ],
                    ),

                    m(
                      "dd",
                      {
                        className: parseClass([
                          "mt-2",
                          "text-base",
                          "leading-7",
                          "text-gray-600",
                        ]),
                      },
                      [
                        "Generate comprehensive student and class performance reports, allowing insights into academic trends and progress.",
                      ],
                    ),
                  ],
                ),
              ],
            ),
          ],
        ),
      ],
    );
  },
};
