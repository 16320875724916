import { parseClass } from "../../../utils/css-class.js";

export const Header = {
  showMenu: false,
  useMobileMenu: false,
  oninit() {},
  view(_vnode) {
    return m(
      "header",
      {
        className: parseClass(["absolute", "inset-x-0", "top-0", "z-50"]),
      },
      [
        m(
          "nav",
          {
            className: parseClass([
              "flex",
              "items-center",
              "justify-between",
              "p-6",
              "lg:px-8",
            ]),
            "aria-label": "Global",
          },
          [
            m(
              "div",
              {
                className: parseClass(["flex", "lg:flex-1"]),
              },
              [
                m(
                  "a",
                  {
                    href: "#",
                    className: parseClass(["-m-1.5", "p-1.5"]),
                  },
                  [
                    m(
                      "span",
                      {
                        className: parseClass(["sr-only"]),
                      },
                      ["Results Africa"],
                    ),

                    m(
                      "label",
                      { className: parseClass(["font-bold"]) },
                      "Results Africa",
                    ),
                    // m("img", {
                    //   className: parseClass(["h-8", "w-auto"]),
                    //   src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
                    //   alt: "",
                    // }),
                  ],
                ),
              ],
            ),

            this.useMobileMenu &&
              m(
                "div",
                {
                  className: parseClass(["flex", "lg:hidden"]),
                },
                [
                  m(
                    "button",
                    {
                      type: "button",
                      className: parseClass([
                        "-m-2.5",
                        "inline-flex",
                        "items-center",
                        "justify-center",
                        "rounded-md",
                        "p-2.5",
                        "text-gray-700",
                      ]),
                      onclick: () => (this.showMenu = true),
                    },
                    [
                      m(
                        "span",
                        {
                          className: parseClass(["sr-only"]),
                        },
                        "Open Main Menu",
                      ),

                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                      </svg>,
                    ],
                  ),
                ],
              ),

            this.useMobileMenu &&
              m(
                "div",
                {
                  className: parseClass(["hidden", "lg:flex", "lg:gap-x-12"]),
                },
                [
                  m(
                    "a",
                    {
                      href: "#",
                      className: parseClass([
                        "text-sm",
                        "font-semibold",
                        "leading-6",
                        "text-gray-900",
                      ]),
                    },
                    "Product",
                  ),

                  m(
                    "a",
                    {
                      href: "#",
                      className: parseClass([
                        "text-sm",
                        "font-semibold",
                        "leading-6",
                        "text-gray-900",
                      ]),
                    },
                    "Features",
                  ),

                  m(
                    "a",
                    {
                      href: "#",
                      className: parseClass([
                        "text-sm",
                        "font-semibold",
                        "leading-6",
                        "text-gray-900",
                      ]),
                    },
                    "Marketplace",
                  ),

                  m(
                    "a",
                    {
                      href: "#",
                      className: parseClass([
                        "text-sm",
                        "font-semibold",
                        "leading-6",
                        "text-gray-900",
                      ]),
                    },
                    "Company",
                  ),
                ],
              ),

            this.useMobileMenu &&
              m(
                "div",
                {
                  className: parseClass([
                    "hidden",
                    "lg:flex",
                    "lg:flex-1",
                    "lg:justify-end",
                  ]),
                },
                [
                  m(
                    "a",
                    {
                      href: "#",
                      className: parseClass([
                        "text-sm",
                        "font-semibold",
                        "leading-6",
                        "text-gray-900",
                      ]),
                    },
                    ["Log in", m("span", { "aria-hidden": true }, [" →"])],
                  ),
                ],
              ),
          ],
        ),

        this.showMenu &&
          m(
            "div",
            {
              className: parseClass(["lg:hidden"]),
              role: "dialog",
              "aria-modal": "true",
            },
            [
              m("div", {
                className: parseClass(["fixed", "inset-0", "z-50"]),
              }),

              m(
                "div",
                {
                  className: parseClass([
                    "fixed",
                    "inset-y-0",
                    "right-0",
                    "z-50",
                    "w-full",
                    "overflow-y-auto",
                    "bg-white",
                    "px-6",
                    "py-6",
                    "sm:max-w-sm",
                    "sm:ring-1",
                    "sm:ring-gray-900/10",
                  ]),
                },
                [
                  m(
                    "div",
                    {
                      className: parseClass([
                        "flex",
                        "items-center",
                        "justify-between",
                      ]),
                    },
                    [
                      m(
                        "a",
                        {
                          href: "#",
                          className: parseClass(["-m-1.5", "p-1.5"]),
                        },
                        [
                          m(
                            "span",
                            {
                              className: parseClass(["sr-only"]),
                            },
                            ["Results Africa"],
                          ),

                          m(
                            "label",
                            { className: parseClass(["font-bold"]) },
                            "Results Africa",
                          ),
                          // m("img", {
                          //   className: parseClass(["h-8", "w-auto"]),
                          //   src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
                          //   alt: "",
                          // }),
                        ],
                      ),

                      m(
                        "button",
                        {
                          type: "button",
                          className: parseClass([
                            "-m-2.5",
                            "rounded-md",
                            "p-2.5",
                            "text-gray-700",
                          ]),
                          onclick: () => (this.showMenu = false),
                        },
                        [
                          m(
                            "span",
                            {
                              className: parseClass(["sr-only"]),
                            },
                            "Close Main Menu",
                          ),

                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>,
                        ],
                      ),
                    ],
                  ),

                  m("div", { className: parseClass(["mt-6", "flow-root"]) }, [
                    m(
                      "div",
                      {
                        className: parseClass([
                          "-my-6",
                          "divide-y",
                          "divide-gray-500/10",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass(["space-y-2", "py-6"]),
                          },
                          [
                            m(
                              "a",
                              {
                                href: "#",
                                className: parseClass([
                                  "-mx-3",
                                  "block",
                                  "rounded-lg",
                                  "px-3",
                                  "py-2",
                                  "text-base",
                                  "font-semibold",
                                  "leading-7",
                                  "text-gray-900",
                                  "hover:bg-gray-50",
                                ]),
                              },
                              ["Product"],
                            ),

                            m(
                              "a",
                              {
                                href: "#",
                                className: parseClass([
                                  "-mx-3",
                                  "block",
                                  "rounded-lg",
                                  "px-3",
                                  "py-2",
                                  "text-base",
                                  "font-semibold",
                                  "leading-7",
                                  "text-gray-900",
                                  "hover:bg-gray-50",
                                ]),
                              },
                              ["Feature"],
                            ),

                            m(
                              "a",
                              {
                                href: "#",
                                className: parseClass([
                                  "-mx-3",
                                  "block",
                                  "rounded-lg",
                                  "px-3",
                                  "py-2",
                                  "text-base",
                                  "font-semibold",
                                  "leading-7",
                                  "text-gray-900",
                                  "hover:bg-gray-50",
                                ]),
                              },
                              ["Marketplace"],
                            ),

                            m(
                              "a",
                              {
                                href: "#",
                                className: parseClass([
                                  "-mx-3",
                                  "block",
                                  "rounded-lg",
                                  "px-3",
                                  "py-2",
                                  "text-base",
                                  "font-semibold",
                                  "leading-7",
                                  "text-gray-900",
                                  "hover:bg-gray-50",
                                ]),
                              },
                              ["Company"],
                            ),
                          ],
                        ),

                        m("div", { className: parseClass(["py-6"]) }, [
                          m(
                            "a",
                            {
                              href: "#",
                              className: parseClass([
                                "-mx-3",
                                "block",
                                "rounded-lg",
                                "px-3",
                                "py-2.5",
                                "text-base",
                                "font-semibold",
                                "leading-7",
                                "text-gray-900",
                                "hover:bg-gray-50",
                              ]),
                            },
                            ["Log in"],
                          ),
                        ]),
                      ],
                    ),
                  ]),
                ],
              ),
            ],
          ),
      ],
    );
  },
};
