import { parseClass } from "../../utils/css-class.js";

export default {
  oninit() {},
  view() {
    return m(
      "div",
      {
        className: parseClass([
          "fixed",
          "w-full",
          "h-full",
          "z-[10]",
          "backdrop-blur-sm",
          "flex",
          "flex-col",
          "justify-center",
          "items-center",
        ]),
      },
      m("span", {
        className: parseClass(["loading", "loading-spinner", "loading-lg"]),
      }),
    );
  },
};
