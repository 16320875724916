import { parseClass } from "../../../../utils/css-class.js";

export const Testimonial = {
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass([
          "mx-auto",
          "mt-32",
          "max-w-7xl",
          "sm:mt-56",
          "sm:px-6",
          "lg:px-8",
        ]),
      },
      [
        m(
          "div",
          {
            className: parseClass([
              "relative",
              "overflow-hidden",
              "bg-gray-900",
              "px-6",
              "py-20",
              "shadow-xl",
              "sm:rounded-3xl",
              "sm:px-10",
              "sm:py-24",
              "md:px-12",
              "lg:px-20",
            ]),
          },
          [
            m("img", {
              className: parseClass([
                "absolute",
                "inset-0",
                "h-full",
                "w-full",
                "object-cover",
                "brightness-150",
                "saturate-0",
              ]),
              src: "https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80",
              alt: "",
            }),

            m("div", {
              className: parseClass([
                "absolute",
                "inset-0",
                "bg-gray-900/90",
                "mix-blend-multiply",
              ]),
            }),

            m(
              "div",
              {
                className: parseClass([
                  "absolute",
                  "-left-80",
                  "-top-56",
                  "transform-gpu",
                  "blur-3xl",
                ]),
                "aria-hidden": true,
              },
              [
                m("div", {
                  className: parseClass([
                    "aspect-[1097/845]",
                    "w-[68.5625rem]",
                    "bg-gradient-to-r",
                    "from-[#ff4694]",
                    "to-[#776fff]",
                    "opacity-[0.45]",
                  ]),
                  style: {
                    "clip-path":
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  },
                }),
              ],
            ),

            m(
              "div",
              {
                className: parseClass([
                  "hidden",
                  "md:absolute",
                  "md:bottom-16",
                  "md:left-[50rem]",
                  "md:block",
                  "md:transform-gpu",
                  "md:blur-3xl",
                ]),
                "aria-hidden": true,
              },
              [
                m("div", {
                  className: parseClass([
                    "aspect-[1097/845]",
                    "w-[68.5625rem]",
                    "bg-gradient-to-r",
                    "from-[#ff4694]",
                    "to-[#776fff]",
                    "opacity-25",
                  ]),
                  style: {
                    "clip-path":
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  },
                }),
              ],
            ),

            m(
              "div",
              {
                className: parseClass([
                  "relative",
                  "mx-auto",
                  "max-w-2xl",
                  "lg:mx-0",
                ]),
              },
              [
                m("img", {
                  className: parseClass(["h-12", "w-auto"]),
                  src: "https://tailwindui.com/img/logos/workcation-logo-white.svg",
                  alt: "",
                }),

                m("figure", {}, [
                  m(
                    "blockquote",
                    {
                      className: parseClass([
                        "mt-6",
                        "text-lg",
                        "font-semibold",
                        "text-white",
                        "sm:text-xl",
                        "sm:leading-8",
                      ]),
                    },
                    [
                      m("p", {}, [
                        "“Amet amet eget scelerisque tellus sit neque faucibus non eleifend. Integer eu praesent at a. Ornare arcu gravida natoque erat et cursus tortor consequat at. Vulputate gravida sociis enim nullam ultricies habitant malesuada lorem ac.”",
                      ]),
                    ],
                  ),

                  m(
                    "figcaption",
                    {
                      className: parseClass([
                        "mt-6",
                        "text-base",
                        "text-white",
                      ]),
                    },
                    [
                      m(
                        "div",
                        {
                          className: parseClass(["font-semibold"]),
                        },
                        ["Judith Black"],
                      ),

                      m(
                        "div",
                        {
                          className: parseClass(["mt-1"]),
                        },
                        ["Proprietor of Indomitable Genius Academy"],
                      ),
                    ],
                  ),
                ]),
              ],
            ),
          ],
        ),
      ],
    );
  },
};
