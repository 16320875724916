import domReady from "domready";
import m from "mithril";
import stream from "mithril/stream.js";

import { createApp } from "./base.js";
import initApp from "./bin/init.js";
import "./style.css";

domReady(() => {
  initApp(m, stream);

  const appShell = document.getElementById("app");
  return createApp({ defaultRoute: "/", shell: appShell });
});
