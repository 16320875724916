import { parseClass } from "../../utils/css-class.js";
import { Footer } from "./footer/index.js";
import { Header } from "./header/index.js";
import { CTA } from "./content/cta/index.js";
import { Testimonial } from "./content/testimonial/index.js";
import { Feature } from "./content/feature/index.js";
import { LogoCloud } from "./content/logo-cloud/index.js";
import { Hero } from "./content/hero/index.js";
import { FAQ } from "./content/faq/index.js";
import { Contact } from "./content/contact/index.js";

export const HomePage = {
  showFAQ: false,
  showLogoCloud: false,
  oninit() {},
  view(_vnode) {
    return [
      // Header
      m(Header),

      // Content
      m(
        "main",
        {
          className: parseClass(["isolate"]),
        },
        [
          // Hero Section
          m(Hero),

          // Logo Cloud
          this.showLogoCloud && m(LogoCloud),

          // Feature Section
          m(Feature),

          // Testimonial Section
          m(Testimonial),

          // FAQ Section
          this.showFAQ && m(FAQ),

          // CTA Section
          m(CTA),

          // Contact Section
          m(Contact, { show: true }),
        ],
      ),

      // Footer
      m(Footer),
    ];
  },
};
