import { parseClass } from "../../../../utils/css-class.js";

export const FAQ = {
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass([
          "mx-auto",
          "max-w-2xl",
          "divide-y",
          "divide-gray-900/10",
          "px-6",
          "pb-8",
          "sm:pb-24",
          "sm:pt-12",
          "lg:max-w-7xl",
          "lg:px-8",
          "lg:pb-32",
        ]),
      },
      [
        m(
          "h2",
          {
            className: parseClass([
              "text-2xl",
              "font-bold",
              "leading-10",
              "tracking-tight",
              "text-gray-900",
            ]),
          },
          ["Frequently asked questions"],
        ),

        m(
          "dl",
          {
            className: parseClass([
              "mt-10",
              "space-y-8",
              "divide-y",
              "divide-gray-900/10",
            ]),
          },
          [
            m(
              "div",
              {
                className: parseClass([
                  "pt-8",
                  "lg:grid",
                  "lg:grid-cols-12",
                  "lg:gap-8",
                ]),
              },
              [
                m(
                  "dt",
                  {
                    className: parseClass([
                      "text-base",
                      "font-semibold",
                      "leading-7",
                      "text-gray-900",
                      "lg:col-span-5",
                    ]),
                  },
                  ["What's the best thing about Switzerland?"],
                ),

                m(
                  "dd",
                  {
                    className: parseClass(["mt-4", "lg:col-span-7", "lg:mt-0"]),
                  },
                  [
                    m(
                      "p",
                      {
                        className: parseClass([
                          "text-base",
                          "leading-7",
                          "text-gray-600",
                        ]),
                      },
                      [
                        "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
                      ],
                    ),
                  ],
                ),
              ],
            ),
          ],
        ),
      ],
    );
  },
};
