import { parseClass } from "../../../../utils/css-class.js";

export const Contact = {
  oninit() {},
  view(_vnode) {
    return m(
      "div",
      {
        className: parseClass(["py-24", "sm:py-32", "mt-32"]),
      },
      [
        m(
          "div",
          {
            className: parseClass(["mx-auto", "max-w-7xl", "px-6", "lg:px-8"]),
          },
          [
            m(
              "div",
              {
                className: parseClass([
                  "mx-auto",
                  "max-w-2xl",
                  "space-y-16",
                  "divide-y",
                  "divide-gray-100",
                  "lg:mx-0",
                  "lg:max-w-none",
                ]),
              },
              [
                m(
                  "div",
                  {
                    className: parseClass([
                      "grid",
                      "grid-cols-1",
                      "gap-x-8",
                      "gap-y-10",
                      "lg:grid-cols-3",
                    ]),
                  },
                  [
                    m("div", {}, [
                      m(
                        "h2",
                        {
                          className: parseClass([
                            "text-3xl",
                            "font-bold",
                            "tracking-tight",
                            "text-gray-900",
                          ]),
                        },
                        ["Contact Us"],
                      ),
                      m(
                        "p",
                        {
                          className: parseClass([
                            "mt-4",
                            "leading-7",
                            "text-gray-600",
                          ]),
                        },
                        [
                          "Have questions or need support? Reach out to us anytime, and our team will be happy to help you.",
                        ],
                      ),
                    ]),

                    m(
                      "div",
                      {
                        className: parseClass([
                          "grid",
                          "grid-cols-1",
                          "gap-6",
                          "sm:grid-cols-2",
                          "lg:col-span-2",
                          "lg:gap-8",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass([
                              "rounded-2xl",
                              "bg-gray-50",
                              "p-10",
                            ]),
                          },
                          [
                            m(
                              "h3",
                              {
                                className: parseClass([
                                  "text-base",
                                  "font-semibold",
                                  "leading-7",
                                  "text-gray-900",
                                ]),
                              },
                              ["Support"],
                            ),
                            m(
                              "dl",
                              {
                                className: parseClass([
                                  "mt-3",
                                  "space-y-1",
                                  "text-sm",
                                  "leading-6",
                                  "text-gray-600",
                                ]),
                              },
                              [
                                m("div", {}, [
                                  m(
                                    "dt",
                                    { className: parseClass(["sr-only"]) },
                                    ["Email Address"],
                                  ),
                                  m("dd", {}, [
                                    m(
                                      "a",
                                      {
                                        className: parseClass([
                                          "font-semibold",
                                          "text-indigo-600",
                                        ]),
                                        href: "mailto:support@results.africa",
                                      },
                                      ["support@results.africa"],
                                    ),
                                  ]),
                                ]),
                                m("div", { className: parseClass(["mt-1"]) }, [
                                  m(
                                    "dt",
                                    { className: parseClass(["sr-only"]) },
                                    ["Phone number"],
                                  ),
                                  m("dd", {}, ["+234 (816) 309-2891"]),
                                ]),
                              ],
                            ),
                          ],
                        ),
                      ],
                    ),
                  ],
                ),

                m(
                  "div",
                  {
                    className: parseClass([
                      "grid",
                      "grid-cols-1",
                      "gap-x-8",
                      "gap-y-10",
                      "pt-16",
                      "lg:grid-cols-3",
                    ]),
                  },
                  [
                    m("div", {}, [
                      m(
                        "h2",
                        {
                          className: parseClass([
                            "text-3xl",
                            "font-bold",
                            "tracking-tight",
                            "text-gray-900",
                          ]),
                        },
                        ["Our Address"],
                      ),
                      m(
                        "p",
                        {
                          className: parseClass([
                            "mt-4",
                            "leading-7",
                            "text-gray-600",
                          ]),
                        },
                        [
                          "Find us at our convenient location, where we’re always ready to assist you with all your school management needs.",
                        ],
                      ),
                    ]),

                    m(
                      "div",
                      {
                        className: parseClass([
                          "grid",
                          "grid-cols-1",
                          "gap-6",
                          "sm:grid-cols-2",
                          "lg:col-span-2",
                          "lg:gap-8",
                        ]),
                      },
                      [
                        m(
                          "div",
                          {
                            className: parseClass([
                              "rounded-2xl",
                              "bg-gray-50",
                              "p-10",
                            ]),
                          },
                          [
                            m(
                              "h3",
                              {
                                className: parseClass([
                                  "text-base",
                                  "font-semibold",
                                  "leading-7",
                                  "text-gray-900",
                                ]),
                              },
                              ["Business Address"],
                            ),
                            m(
                              "address",
                              {
                                className: parseClass([
                                  "mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600",
                                ]),
                              },
                              [
                                m("p", {}, ["F.O Williams Street 34, 900108"]),
                                m("p", {}, [
                                  "Life Camp - Abuja, Federal Capital Territory",
                                ]),
                                m("p", {}, ["Nigeria"]),
                              ],
                            ),
                          ],
                        ),
                      ],
                    ),
                  ],
                ),
              ],
            ),
          ],
        ),
      ],
    );
  },
};
