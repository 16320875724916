export const parseClass = (classList) => {
  let classes = classList;
  if (!Array.isArray(classList)) {
    classes = [classes];
  }

  return classes
    .filter((c) => !!c)
    .reduce((list, entry) => {
      if (Array.isArray(entry)) {
        list = list.concat(entry);
      } else {
        list.push(entry);
      }
      return list;
    }, [])
    .join(" ");
};
