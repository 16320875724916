import underscore from "underscore";

export default (m, stream) => {
  window.m = m; // Attach mithril object globally
  window.stream = stream; // Attach mithril stream object globally

  window.useLoader = stream(false);

  window.underscore = underscore;

  window.useTitle = (title = "Results Africa") => {
    document.title = `Results Africa - ${title}`;
  };
};
