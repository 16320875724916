import { NoAuthShell } from "../shells/no-auth/index.js";

/**
 * noAuthRoute
 * @param {string} component
 * @param {null | string} stateKey
 * @return {*}
 */
function noAuthRoute(component, stateKey = null) {
  return m(NoAuthShell, m(component, { stateKey }));
}

export default (routes) => {
  const router = {};

  for (const route of Object.keys(routes)) {
    const { api, key, page, path } = routes[route];

    const pathObject = {
      onmatch: async (keys, _requestedPath) => {
        try {
          if (api) {
            useLoader(true);
            const data = sm.read(key);
            if (!data) {
              const { data, meta } = await api(keys);
              const payload = {};
              if (data) {
                payload["data"] = data;
              }

              if (meta) {
                payload["meta"] = meta;
              }

              if (Object.keys(payload).length > 0) {
                sm.create(key, { ...payload });
              }
            }
          }

          return page;
        } catch (error) {
          const { message, code, statusCode, reqId, status } = error;
          useToast({
            key: code,
            message,
            reqId,
            statusCode,
            status,
          });
        } finally {
          useLoader(false);
        }
      },
      render: ({ tag: page }) => {
        return noAuthRoute(page);
      },
    };

    if (Array.isArray(path)) {
      for (const entry of path) {
        router[entry] = pathObject;
      }
    } else {
      router[path] = pathObject;
    }
  }

  return router;
};
